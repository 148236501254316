<script>
    import { createEventDispatcher, onMount } from "svelte";
    import { register } from "swiper/element/bundle";
    import { fade } from "svelte/transition";

    import logo from "../assets/base/img/logo-white.svg";
    import iPhone_FullScaleImg from "../assets/base/img/onboarding/iPhone_FullScale.webp";
    import iPhone_TableScaleImg from "../assets/base/img/onboarding/iPhone_TableScale.webp";
    import iPhone_ShortlistImg from "../assets/base/img/onboarding/iPhone_Shortlist.webp";
    import iPhone_ShareImg from "../assets/base/img/onboarding/iPhone_Share.webp";

    const dispatch = createEventDispatcher();

    onMount(() => {
        register();
    });
</script>

<div
    class="fixed overflow-y-scroll inset-0 z-200 pt-$safe-area-top pb-$safe-area-bottom bg-black bg-opacity-80 backdrop-filter backdrop-blur-lg"
    transition:fade={{ duration: 200 }}
>
    <div class="relative h-full">
        <swiper-container class="w-full h-full" pagination={true}>
            <swiper-slide>
                <div class="slide-inner">
                    <img class="max-w-100" src={logo} alt="homeAR logo" />
                    <p>
                        Welcome to home<sup>ar</sup>! Here are some quick tips
                        to help you get the most out of your experience.
                    </p>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="slide-inner">
                    <h1>Two AR modes to give you the full picture</h1>
                    <p>
                        <b>Table scale</b> gives you a bird's-eye view of plans,
                        to help you understand the design and layout.
                    </p>
                    <img
                        class="screenshot"
                        src={iPhone_TableScaleImg}
                        alt="iPhone table scale mode"
                    />
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="slide-inner">
                    <h1>Two AR modes to give you the full picture</h1>
                    <p>
                        <b>Full scale</b> lets you take a walk through the virtual
                        home to experience just what it would be like to live in.
                    </p>
                    <img
                        class="screenshot"
                        src={iPhone_FullScaleImg}
                        alt="iPhone full scale mode"
                    />
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="slide-inner">
                    <h1>Compile your own shortlist</h1>
                    <p>
                        Build your own shortlist of plans to help you make
                        design decisions.
                    </p>
                    <img
                        class="screenshot"
                        src={iPhone_ShortlistImg}
                        alt="iPhone shortlist screen"
                    />
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="slide-inner">
                    <h1>Share with friends and family</h1>
                    <p>
                        home<sup>ar</sup> makes it easy to share plans with the people
                        that matter - via email, text or instant messaging.
                    </p>
                    <img
                        class="screenshot"
                        src={iPhone_ShareImg}
                        alt="iPhone share screen"
                    />
                    <button
                        class="text-$color-primary p-2"
                        on:click={() => dispatch("finish")}
                    >
                        OK got it!
                    </button>
                </div>
            </swiper-slide>
        </swiper-container>
    </div>
</div>

<style>
    swiper-slide {
        @apply flex flex-col items-center justify-center;
    }

    .slide-inner {
        @apply flex flex-col items-center justify-center text-white p-10 w-full max-w-150 h-full;
    }

    img.screenshot {
        @apply h-8/10 w-auto;
    }

    swiper-slide p {
        @apply text-center;
    }

    swiper-slide h1 {
        @apply text-center text-xl font-bold mb-2;
    }
</style>
