<script>
    import { onDestroy, onMount } from "svelte";
    import PlanCard from "../lib/PlanCard.svelte";
    import Spinner from "../lib/Spinner.svelte";
    import {
        offlinePlans,
        refreshShortlist,
        shortlist,
        shortlistInitialized,
        showPlan,
    } from "../store";
    import PullToRefresh from "../pulltorefreshjs";
    import * as Analytics from "../analytics";

    let refreshPtr;

    onMount(() => {
        Analytics.screen("Shortlist", "Mobile");
        if (!$shortlistInitialized) refreshShortlist();

        refreshPtr = PullToRefresh.init({
            mainElement: "#pullToRefreshWrapper",
            triggerElement: "#pullToRefreshWrapper",
            onRefresh() {
                refreshShortlist().then(() => offlinePlans.reload());
            },
            iconArrow: "refresh",
            iconRefreshing: "sync",
            distThreshold: 70,
            distMax: 100,
            distReload: 70,
            distIgnore: 0,
            refreshTimeout: 300,
        });
    });

    onDestroy(() => {
        refreshPtr.destroy();
    });
</script>

<div id="pullToRefreshWrapper" class="min-h-60vh">
    {#if !$shortlistInitialized}
        <Spinner />
    {:else}
        <div class="flex flex-row flex-wrap justify-evenly gap-5 p-5">
            {#if $shortlist?.length}
                {#each $shortlist as plan (plan.id)}
                    <div
                        class="w-85 md:w-[calc(50%-2.5rem)] xl:w-[calc(33%-2.5rem)] h-50 md:h-60 lg:h-70"
                    >
                        <PlanCard {plan} on:click={() => showPlan(plan)} />
                    </div>
                {/each}
            {:else}
                <div class="text-center">
                    Your shortlist is empty! Add plans by using the <span
                        class="material-symbols-rounded text-xl text-$color-primary leading-1 align-middle"
                    >
                        favorite
                    </span> symbol in the top-right corner of a plan.
                </div>
            {/if}
        </div>
    {/if}
</div>
