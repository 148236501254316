import { FloorAreas, PlanVisibility } from "./constants";

/**
 * @param {string} color
 */
export function setBackgroundColor(color) {
    document.body.style.setProperty("background-color", color);
}

export function resetBackgroundColor() {
    document.body.style.removeProperty("background-color");
}

/** @param {FilterConfig} fc */
export function getFilterString(fc) {
    const filters = [];

    if (fc) {
        if (fc.keywords)
            filters.push(
                `Keywords@=*${fc.keywords.trim().split(" ").join("|")}`
            );

        if (fc.minBedrooms) filters.push(`BedroomCount>=${fc.minBedrooms}`);
        if (fc.maxBedrooms) filters.push(`BedroomCount<=${fc.maxBedrooms}`);

        if (fc.minBathrooms) filters.push(`BathroomCount>=${fc.minBathrooms}`);
        if (fc.maxBathrooms) filters.push(`BathroomCount<=${fc.maxBathrooms}`);

        if (fc.minBudget) filters.push(`Price>=${fc.minBudget}`);
        if (fc.maxBudget) filters.push(`Price<=${fc.maxBudget}`);

        if (fc.floorArea) {
            const area = FloorAreas[fc.floorArea];
            if (area.max > 0) {
                filters.push(`SquareMeters>=${area.min}`);
                filters.push(`SquareMeters<=${area.max}`);
            } else if (area.max === -1) {
                filters.push(`SquareMeters>=${area.min}`);
            }
        }

        if (fc.propertyType) filters.push(`PropertyTypes==${fc.propertyType}`);

        if (fc.region) {
            filters.push(`Region==${fc.region}`);
            if (fc.district) {
                filters.push(`District==${fc.district}`);
                if (fc.suburb) {
                    filters.push(`Suburb==${fc.suburb}`);
                }
            }
        }
    }

    if (!fc?.showPrivatePlans) {
        filters.push(`Visibility!=${PlanVisibility.private}`);
    }

    return filters.join(",");
}
