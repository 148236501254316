import {
    derived,
    get,
    writable,
    asyncDerived,
    persisted,
    asyncReadable,
} from "@square/svelte-store";
import {
    deleteShortlist,
    getAccount,
    getCountries,
    getDiscover,
    getPlan,
    getPropertyTypes,
    getRegions,
    getUserCatalogue,
    getUserConfig,
    postShortlist,
} from "./api";
import { getShortlist } from "./api";
import { PlanCatalogueStore } from "./planCatalogueStore";
import jwtDecode from "jwt-decode";
import nativeRequest from "./nativeRequest";

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

// APP -------------------------------------------------
/** @type {import("@square/svelte-store").Writable<Auth>} */
export let auth = writable();

export let account = asyncDerived(
    [auth],
    async ([$auth]) => {
        return $auth ? await getAccount() : null;
    },
    { reloadable: true }
);

export let userConfig = asyncDerived([auth], async ([$auth]) => {
    return $auth ? await getUserConfig() : null;
});

export let userIsAnonymous = derived([auth], ([$auth]) => {
    //! This is meant to be determined by userConfig.describeAsLoggedIn but that seems to be unreliable.
    return $auth ? !!$auth.user?.roles?.find((v) => v === "Anonymous") : false;
});

/** @type {import("@square/svelte-store").Loadable<AuthToken>} */
export let decodedAuthToken = derived([auth], ([$auth]) => {
    return $auth ? jwtDecode($auth.auth_token) : null;
});

export let currentNav = writable(-1);

const planStack = writable([]);

/** @type {import("@square/svelte-store").Readable<Home>} */
export let currentPlan = derived(planStack, ($planStack) => $planStack.at(-1));

export async function showPlanId(planId) {
    showPlan(await getPlan(planId));
}

export function showPlan(plan) {
    planStack.update((s) => {
        s.push(plan);
        return s;
    });
}

export function showPreviousPlan() {
    planStack.update((s) => {
        s.pop();
        return s;
    });
}

export const safeArea = persisted(null, "safeArea", {
    storageType: "LOCAL_STORAGE",
});

export const onboardingComplete = persisted(false, "onboardingComplete", {
    storageType: "LOCAL_STORAGE",
});

export const showDevOptions = writable(
    import.meta.env.DEV ||
        (!!import.meta.env.VITE_DEV_SECRET &&
            new URLSearchParams(location.search)?.get("dev") ===
                import.meta.env.VITE_DEV_SECRET)
);

// SHORTLIST -------------------------------------------
/** @type {import("@square/svelte-store").Writable<Home[]>} */
export let shortlist = writable([]);

export let shortlistInitialized = writable(false);

export async function refreshShortlist() {
    shortlistInitialized.set(false);
    const data = await getShortlist();
    shortlist.set(data);
    shortlistInitialized.set(true);
    const cachedShortlist = data.map((h) => ({
        id: h.id,
        name: h.name,
        supplierName: h.supplierName,
    }));
    await nativeRequest("cache_fav_put", {
        d: JSON.stringify(cachedShortlist),
    });
}

export async function shortlistPlan(planId) {
    await postShortlist(planId);
    await sleep(500);
    await refreshShortlist();

    // Sometime the API doesn't add to shortlist immediately. Hopefully after a second attempt it will be updated.
    if (get(shortlist)?.find((h) => h.id === planId)) {
        await sleep(500);
        await refreshShortlist();
    }
}

export async function unShortlistPlan(planId) {
    await deleteShortlist(planId);
    await sleep(500);
    await refreshShortlist();

    // Sometime the API doesn't remove the shortlist immediately. Hopefully after a second attempt it will be updated.
    if (get(shortlist)?.find((h) => h.id === planId)) {
        await sleep(500);
        await refreshShortlist();
    }
}

// OFFLINE PLANS ----------------------------------------
/** @type {import("@square/svelte-store").Loadable<Home[]>} */
export let offlinePlans = asyncReadable(
    [],
    async () => {
        return (await nativeRequest("cache_fav_request"))?.shortlist || [];
    },
    { reloadable: true }
);

/** @type {import("@square/svelte-store").Loadable<{id: string, filename: string}[]>} */
export let cachedPlans = asyncReadable(
    [],
    async () => {
        return (await nativeRequest("cache_request"))?.plans || [];
    },
    { reloadable: true }
);

export async function planAvailableOffline(planId) {
    return !!get(offlinePlans)?.find((p) => p.id === planId);
}

document.addEventListener("arSessionEnd", async () => {
    await cachedPlans.reload();
    await offlinePlans.reload();
});

// DISCOVER --------------------------------------------
// 730 is approximately the width where there are two or three rows of PlanCards so we need more plans to fill the screen.
export const discoverStore = new PlanCatalogueStore(
    getDiscover,
    window.innerWidth < 730 ? 10 : 20
);
/** @type {import("@square/svelte-store").Writable<FilterConfig>} */
export const discoverFilters = writable(null);

// CATALOGUE -------------------------------------------
export const userCatalogueStore = new PlanCatalogueStore(
    getUserCatalogue,
    window.innerWidth < 730 ? 10 : 20
);
/** @type {import("@square/svelte-store").Writable<FilterConfig>} */
export const catalogueFilters = writable(null);

// EVENTS ----------------------------------------------
auth.subscribe(async (a) => {
    discoverStore.empty();
    userCatalogueStore.empty();
    shortlist.set([]);
    shortlistInitialized.set(false);
    cachedPlans.reload();
    offlinePlans.reload();
    if (!a) currentNav.set(-1);
});

// STATIC ---------------------------------------------
export const propertyTypeStore = asyncReadable([], getPropertyTypes);
export const regionStore = asyncReadable([], getRegions);
export const countryStore = asyncReadable([], getCountries);
export const visibleCountryStore = derived(countryStore, (c) =>
    c.filter((c) => c.isVisibleOnApp)
);

// PRELOAD IMAGES --------------------------------------
// Preloading images so we don't get a flicker as they
// pop into the ARModeSelector.
import fullScaleImg from "./assets/base/img/armode-full-scale.webp";
import tableScaleImg from "./assets/base/img/armode-table-scale.webp";

const fullScale = new Image();
fullScale.src = fullScaleImg;
const tableScale = new Image();
tableScale.src = tableScaleImg;
