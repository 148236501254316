<script>
    import { createEventDispatcher, onMount } from "svelte";
    import fullScaleImg from "../assets/base/img/armode-full-scale.webp";
    import tableScaleImg from "../assets/base/img/armode-table-scale.webp";
    import { fade } from "svelte/transition";
    import { auth } from "../store";
    import nativeRequest from "../nativeRequest";

    /** @type {Home} */ export let plan;

    const dispatch = createEventDispatcher();

    let currentMode = "fs";
    let loadingAR = false;
    let canSwitchMode = true;

    onMount(() => {
        currentMode = plan.fullScale ? "fs" : "ts";
        canSwitchMode = plan.fullScale && plan.tableScale;
    });

    async function startAR() {
        if (loadingAR) return;
        loadingAR = true;
        try {
            await nativeRequest("start_ar", {
                home_id: plan.id,
                ar_mode: currentMode,
                auth: $auth?.auth_token,
                api: import.meta.env.PROD ? "prod" : "dev",
            });
            setTimeout(() => cancel(), 2000); // Timeout to give time for the AR view to load so we don't immediately disappear.
        } finally {
            loadingAR = false;
        }
    }

    function cancel() {
        dispatch("cancel");
    }

    function switchMode() {
        if (currentMode === "fs" && plan.tableScale) {
            currentMode = "ts";
        } else if (currentMode === "ts" && plan.fullScale) {
            currentMode = "fs";
        }
    }
</script>

<div
    class="fixed flex justify-center left-0 top-0 w-full h-full bg-black bg-opacity-90 blur-lg z-200 pt-$safe-area-top pb-$safe-area-bottom px-5"
    transition:fade={{ duration: 100 }}
>
    <div
        class="flex flex-col w-full h-full justify-between text-white"
        style="max-width: min(60vh, 45rem);"
    >
        <div class="flex flex-col gap-6">
            <div class="flex flex-row">
                <button
                    class="rounded-full bg-true-gray-600 bg-opacity-70 h-12 w-30 flex flex-row items-center justify-center"
                    on:click={cancel}
                >
                    <span class="material-symbols-rounded"> close </span>
                    Cancel
                </button>
            </div>
            <div class="text-center text-2xl font-bold">
                {#if currentMode === "fs"}
                    Full Scale View
                {:else}
                    Doll's House View
                {/if}
            </div>
            {#if currentMode === "fs"}
                <img
                    src={fullScaleImg}
                    alt="Full Scale View"
                    class="rounded-2xl"
                />
                <div>
                    The perfect way to experience a new plan. This is 1:1 scale
                    so you'll need plenty of space to walk around, inside and
                    out.
                </div>
            {:else}
                <img
                    src={tableScaleImg}
                    alt="Table Scale View"
                    class="rounded-2xl"
                />
                <div>
                    Ideal for 3D viewing when you don't have space for Full
                    Scale walk-through.
                </div>
            {/if}
        </div>
        <div class="flex flex-col mb-4">
            <button
                class="bg-$color-primary h-12 rounded-full text-lg text-center disabled:opacity-50 disabled:cursor-default"
                on:click={startAR}
                disabled={loadingAR}
            >
                {#if currentMode === "fs"}
                    Full Scale
                {:else}
                    Doll's House
                {/if}
            </button>
            <hr class="my-5" />
            {#if canSwitchMode}
                <button
                    class="text-center flex flex-col items-center"
                    on:click={switchMode}
                >
                    {#if currentMode === "fs"}
                        Indoors or restricted space?
                    {:else}
                        &#8203;
                    {/if}
                    <span class="text-$color-primary">
                        {#if currentMode === "fs"}
                            Use the Doll's House view
                        {:else}
                            Switch back to Full Scale
                        {/if}
                    </span>
                </button>
            {/if}
        </div>
    </div>
</div>
