<script>
    /** @type Home */
    export let plan;

    function getFloorArea() {
        // Get `plan.squareMeters` and convert to square feet if locale is US.
        if (navigator.language === "en-US") {
            return (
                Math.round(plan.squareMeters * 10.7639).toLocaleString() + "ft²"
            );
        } else {
            return Math.round(plan.squareMeters).toLocaleString() + "m²";
        }
    }
</script>

<div class="flex flex-row flex-wrap gap-x-2 gap-y-1 text-sm">
    <!-- Details -->
    {#if import.meta.env.VITE_HIDE_BEDROOM_COUNT !== "true"}
        <div class="flex flex-row items-center gap-1">
            <span class="material-symbols-rounded"> bed </span>
            {plan.bedroomCount}
        </div>
    {/if}
    {#if import.meta.env.VITE_HIDE_BATHROOM_COUNT !== "true"}
        <div class="flex flex-row items-center gap-1">
            <span class="material-symbols-rounded"> bathtub </span>
            {plan.bathroomCount}
        </div>
    {/if}
    {#if import.meta.env.VITE_HIDE_GARAGE_COUNT !== "true"}
        <div class="flex flex-row items-center gap-1">
            <span class="material-symbols-rounded"> directions_car </span>
            {plan.garageCount}
        </div>
    {/if}
    {#if import.meta.env.VITE_HIDE_LOUNGE_COUNT !== "true"}
        <div class="flex flex-row items-center gap-1">
            <span class="material-symbols-rounded"> chair </span>
            {plan.loungeCount}
        </div>
    {/if}
    {#if import.meta.env.VITE_HIDE_FLOOR_AREA !== "true"}
        <div class="flex flex-row items-center gap-1">
            <span class="material-symbols-rounded"> space_dashboard </span>
            {getFloorArea()}
        </div>
    {/if}
</div>

<style>
    .material-symbols-rounded {
        font-variation-settings: "FILL" 1;
    }
</style>
