<script>
    import { createEventDispatcher, onMount } from "svelte";
    import {
        login,
        register as registerUser,
        sendPasswordResetEmail,
    } from "../api";
    import Spinner from "./Spinner.svelte";
    import { captureException } from "@sentry/svelte";
    import Modal from "./Modal.svelte";

    const dispatch = createEventDispatcher();

    export let register = false;

    let loginError = "";
    let email = "";
    let password = "";
    let password2 = "";
    let busy = false;

    let firstName = "";
    let lastName = "";
    let phone = "";
    let showRegister = false;
    let showForgotPassword = false;
    let forgotPasswordResult;
    let forgotPasswordModalAction = () => {};

    const incorrectCredsMessage =
        "Incorrect email or password. Please try again.";
    const unknownErrorMessage =
        "An unknown error occurred. Please check your internet connection and try again.";

    onMount(() => {
        if (import.meta.env.VITE_HIDE_REGISTER === "true") register = false;
    });

    async function submit() {
        busy = true;
        loginError = "";
        try {
            await login(email, password);
            dispatch("loginSuccess");
        } catch (e) {
            loginError =
                e.response?.status === 400
                    ? incorrectCredsMessage
                    : unknownErrorMessage;
            if (!e.response?.status || e.response?.status >= 500) {
                captureException(e);
                console.error(e);
            }
            dispatch("loginError", {
                error: e,
            });
        } finally {
            busy = false;
            password = "";
        }
    }

    async function submitRegister() {
        busy = true;
        loginError = "";
        try {
            await registerUser(email, password, firstName, lastName, phone);
            dispatch("registerSuccess");
        } catch (e) {
            if (!e.response?.status || e.response?.status >= 500) {
                captureException(e);
                console.error(e);
            }
            dispatch("registerError", {
                error: e,
            });
        } finally {
            busy = false;
            password = "";
        }
    }

    async function sendForgotPassword() {
        busy = true;
        const successMessage =
            "An email has been sent to the address entered, provided it's registered with us.";
        forgotPasswordResult = null;
        forgotPasswordModalAction = () => {};
        try {
            await sendPasswordResetEmail(email);
            forgotPasswordResult = successMessage;
            forgotPasswordModalAction = () => {
                showForgotPassword = false;
                forgotPasswordResult = null;
            };
        } catch (e) {
            if (!e.response?.status || e.response?.status >= 500) {
                captureException(e);
                console.error(e);
                forgotPasswordResult =
                    "An error occurred resetting your password. Please check your internet connection and try again.";
            } else {
                forgotPasswordResult = successMessage;
                forgotPasswordModalAction = () => {
                    showForgotPassword = false;
                    forgotPasswordResult = null;
                };
            }
        } finally {
            busy = false;
        }
    }
</script>

{#if loginError}
    <Modal ok header="Login Error" body={loginError} />
{/if}

{#if forgotPasswordResult}
    <Modal
        ok
        header="Password Reset"
        body={forgotPasswordResult}
        on:result={forgotPasswordModalAction}
    />
{/if}

{#if busy}
    <div class="flex justify-center"><Spinner /></div>
{:else if showForgotPassword}
    <form
        class="flex flex-col gap-4 text-center"
        on:submit|preventDefault={sendForgotPassword}
    >
        <h3>Forgot Password</h3>
        <p>Send a link to your email to reset your password.</p>
        <input
            type="email"
            placeholder="Email address..."
            class="input-field"
            bind:value={email}
            required
        />
        <button type="submit" class="btn" disabled={!email || busy}>
            Send Email
        </button>
        <button
            type="button"
            class="text-$color-primary text-center p-1"
            on:click={() => (showForgotPassword = false)}
        >
            Back
        </button>
    </form>
{:else if !showRegister}
    <form on:submit|preventDefault={submit} class="flex flex-col gap-4">
        <div class="flex flex-col gap-2">
            <input
                type="email"
                class="input-field"
                placeholder="Email address..."
                required
                bind:value={email}
            />
            <input
                type="password"
                class="input-field"
                placeholder="Password..."
                required
                bind:value={password}
            />
        </div>
        <div class="flex flex-col gap-2">
            <button
                class="btn"
                type="submit"
                disabled={!password || !email || busy}
            >
                Login
            </button>
            {#if register}
                <button
                    class="btn btn-secondary"
                    type="button"
                    on:click={() => (showRegister = true)}
                >
                    Register
                </button>
            {/if}
        </div>
        <button
            type="button"
            class="text-$color-primary text-center p-1"
            on:click={() => (showForgotPassword = true)}
        >
            I forgot my password.
        </button>
    </form>
{:else}
    <form on:submit|preventDefault={submitRegister} class="flex flex-col gap-4">
        <div class="flex flex-col gap-3">
            <div class="flex flex-col gap-1">
                <span class="text-sm mx-4">Name</span>
                <input
                    type="text"
                    class="input-field"
                    placeholder="First name..."
                    required
                    bind:value={firstName}
                />
                <input
                    type="text"
                    class="input-field"
                    placeholder="Last name..."
                    required
                    bind:value={lastName}
                />
            </div>
            <div class="flex flex-col gap-1">
                <span class="text-sm mx-4">Login details</span>
                <input
                    type="email"
                    class="input-field"
                    placeholder="Email address..."
                    required
                    bind:value={email}
                />
                <input
                    type="password"
                    class="input-field"
                    placeholder="Password..."
                    required
                    bind:value={password}
                />
                <input
                    type="password"
                    class="input-field"
                    placeholder="Password again..."
                    required
                    bind:value={password2}
                />
            </div>
            <div class="flex flex-col gap-1">
                <span class="text-sm mx-4">Contact</span>
                <input
                    type="tel"
                    class="input-field"
                    placeholder="Phone number..."
                    required
                    bind:value={phone}
                />
            </div>
        </div>
        <div class="text-red-500 text-center" hidden={!loginError}>
            {loginError}
        </div>
        <div class="flex flex-col gap-2">
            <button
                class="btn"
                type="submit"
                disabled={!password ||
                    !password2 ||
                    password !== password2 ||
                    !email ||
                    !firstName ||
                    !lastName ||
                    !phone ||
                    busy}
            >
                Register
            </button>
            {#if register}
                <button
                    class="btn btn-secondary"
                    type="button"
                    on:click={() => (showRegister = false)}
                >
                    Login
                </button>
            {/if}
        </div>
    </form>
{/if}
