<script>
    import { onDestroy } from "svelte";
    import Header from "./Header.svelte";
    import Nav from "./Nav.svelte";
    import Debug from "../pages/Debug.svelte";
    import PlanCatalogue from "../pages/PlanCatalogue.svelte";
    import Profile from "../pages/Profile.svelte";
    import Shortlist from "../pages/Shortlist.svelte";
    import {
        catalogueFilters,
        currentNav,
        discoverFilters,
        discoverStore,
        userCatalogueStore,
        userIsAnonymous,
    } from "../store";
    import { resetBackgroundColor, setBackgroundColor } from "../utils";
    import Modal from "../lib/Modal.svelte";
    import { logout } from "../api";

    const navNames = ["Discover", "Catalogue", "Shortlist", "Profile", "Debug"];
    let currentCatalogue;

    const bgColorVariables = [
        "--color-bg-discover",
        "--color-bg-catalogue",
        "--color-bg-shortlist",
        "--color-bg-profile",
        "--color-bg-debug",
    ];

    // Set the background color to the current nav's bg color var.
    $: setBackgroundColor(`var(${bgColorVariables[$currentNav]})`);

    let showLogoutModal = false;

    onDestroy(() => {
        resetBackgroundColor();
    });
</script>

<Header>
    <span slot="title" class="w-full">
        <div class="flex flex-row items-center justify-center relative w-full">
            <span>{$currentNav > -1 ? navNames[$currentNav] : ""}</span>
            {#if currentCatalogue}
                <button
                    class="material-symbols-rounded float-right absolute right-4 text-$color-primary"
                    on:click={currentCatalogue.showFilters()}
                >
                    filter_list
                </button>
            {:else if $currentNav === 3 && !$userIsAnonymous}
                <button
                    class="material-symbols-rounded float-right absolute right-4 text-$color-primary"
                    on:click={() => (showLogoutModal = true)}
                >
                    logout
                </button>
            {/if}
        </div>
    </span>
</Header>
<Nav />

{#if $currentNav === 0}
    <PlanCatalogue
        bind:this={currentCatalogue}
        store={discoverStore}
        filterStore={discoverFilters}
        analyticsName="Discover"
    />
{:else if $currentNav === 1}
    <PlanCatalogue
        bind:this={currentCatalogue}
        store={userCatalogueStore}
        filterStore={catalogueFilters}
        showPrivateToggle
        analyticsName="Catalogue"
    />
{:else if $currentNav === 2}
    <Shortlist />
{:else if $currentNav === 3}
    <Profile />
{:else if $currentNav === 4}
    <Debug />
{/if}

<Modal
    confirm
    header="Logout"
    body="Are you sure you want to logout?"
    on:result={(d) => {
        if (d.detail) logout();
    }}
    bind:open={showLogoutModal}
/>
