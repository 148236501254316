<script>
    import { onMount } from "svelte";
    import { logout } from "../api";
    import { auth } from "../store";

    // let selectedServer;

    // onMount(() => {
    //     const override = window.localStorage.getItem("apiOverride");
    //     if (override) selectedServer = override;
    // });

    // function onServerChange(e) {
    //     if (
    //         confirm(
    //             `This will log you out and change the server to ${e.target.value}. Are you sure?`
    //         )
    //     ) {
    //         if (e.target.value === "prod")
    //             window.localStorage.removeItem("apiOverride");
    //         else window.localStorage.setItem("apiOverride", e.target.value);
    //         logout();
    //     } else {
    //         e.target.value = selectedServer;
    //     }
    // }

    function doLogout() {
        if (confirm("Are you sure you want to logout?")) logout();
    }
</script>

<div class="flex flex-col gap-2 p-6">
    <b>API:</b>
    <div class="select-text">{import.meta.env.VITE_API_URL}</div>
    <b>User ID:</b>
    <div class="select-text">{$auth.id}</div>
    <b>Country:</b>
    <div>{$auth.user.countryId}</div>
    <b>Profile complete:</b>
    <div>{$auth.user.profileComplete}</div>
    <b>Mode:</b>
    <div>{import.meta.env.MODE}</div>
    <b>Commit:</b>
    <div class="select-text">
        {import.meta.env.COMMIT_HASH}
    </div>
    <!-- <b>Change API server:</b>
    <select
        class="h-10"
        on:change|preventDefault={onServerChange}
        bind:value={selectedServer}
    >
        <option value="prod">Production</option>
        <option value="dev">Development</option>
    </select> -->
    <button class="btn" on:click={doLogout}>Logout</button>
</div>
