<script>
    import { getPlanCatalogue } from "../api";
    import PlanCard from "../lib/PlanCard.svelte";
    import Spinner from "../lib/Spinner.svelte";
    import { showPlan } from "../store";

    /** @type {Home} */
    export let plan;
</script>

{#await getPlanCatalogue(plan.id)}
    <Spinner />
{:then catalogue}
    {#if catalogue?.length}
        <div class="mt-4 mb-1">More from {plan.supplierName}:</div>
        <div
            class="absolute left-0 right-0 px-4 pb-[calc(var(--safe-area-bottom)+1rem)] overflow-auto"
        >
            <div class="flex flex-row gap-4">
                {#each catalogue as plan (plan.id)}
                    <div class="flex flex-col flex-shrink-0 w-85 h-50">
                        <PlanCard {plan} on:click={() => showPlan(plan)} />
                    </div>
                {/each}
                <!-- Extra element for right padding as x-padding class seems to only work on the left. -->
                <div class="flex flex-col flex-shrink-0 w-0.5" />
            </div>
        </div>
    {/if}
{:catch}
    <div />
{/await}
