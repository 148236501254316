import { get, writable } from "svelte/store";

export class PlanCatalogueStore {
    /**
     * @param {function(number?, number?, string?, string?, string?): Promise<Home[]>} getPlans
     * @param {number} pageSize
     */
    constructor(getPlans, pageSize = 8) {
        /** @type {import("svelte/store").Writable<Home[]>} */
        this.plans = writable([]);
        this.isInitialized = writable(false);
        /** @type {string} */ this.filters = null;
        /** @type {string} */ this.sorts = null;

        let page = 1;
        let orderSeed = Math.random().toString();

        this.refresh = async function (randomize = true) {
            this.isInitialized.set(false);
            const data = await getPlans(
                (page = 1),
                pageSize,
                randomize ? (orderSeed = Math.random().toString()) : orderSeed,
                this.filters,
                this.sorts
            );
            this.plans.set(data);
            this.isInitialized.set(true);
        };

        this.loadMore = async function () {
            const currentPlans = get(this.plans);
            if (pageSize * page > currentPlans.length) return false;

            page++;
            const data = await getPlans(
                page,
                pageSize,
                orderSeed,
                this.filters,
                this.sorts
            );
            this.plans.set(currentPlans.concat(data));
            return true;
        };

        this.empty = function () {
            this.plans.set([]);
            this.isInitialized.set(false);
        };
    }
}
