import App from "./App.svelte";
import "material-symbols/rounded.css";
import "@assets/style.css";

import * as Sentry from "@sentry/svelte";

const shortMode = import.meta.env.PROD ? "prod" : "dev";

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
        new Sentry.BrowserTracing({
            tracePropagationTargets: [
                "localhost",
                "10.0.0.8",
                import.meta.env.VITE_API_URL,
            ],
        }),
    ],
    tracesSampleRate: 1.0,
    environment: `${import.meta.env.VITE_SENTRY_PRODUCT_NAME}-${shortMode}`,
    release: `${import.meta.env.VITE_SENTRY_PRODUCT_NAME}@${
        import.meta.env.COMMIT_HASH
    }`,
    debug: import.meta.env.DEV,
});

const app = new App({
    target: document.getElementById("app"),
});

export default app;
