<script>
    import { createEventDispatcher } from "svelte";

    const dispatch = createEventDispatcher();

    export let checked = false;
</script>

<!-- Source: https://codepen.io/mburnette/pen/LxNxNg -->
<input
    type="checkbox"
    id="switch"
    bind:checked
    on:change={(e) => dispatch("change", e)}
/>
<label for="switch">Toggle</label>

<style>
    input[type="checkbox"] {
        height: 0;
        width: 0;
        visibility: hidden;
    }

    label {
        cursor: pointer;
        text-indent: -9999px;
        width: 57px;
        height: 35px;
        background: grey;
        display: block;
        border-radius: 100px;
        position: relative;
    }

    label:after {
        content: "";
        position: absolute;
        top: 3px;
        left: 3px;
        width: calc(35px - 6px);
        height: calc(35px - 6px);
        background: white;
        border-radius: 35px;
        transition: 0.3s;
        @apply shadow shadow-md;
    }

    input:checked + label {
        background: var(--color-primary);
    }

    input:checked + label:after {
        left: calc(100% - 4px);
        transform: translateX(-100%);
    }

    /* label:active:after {
        width: 50px;
    } */
</style>
