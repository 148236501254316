<script>
    import { onMount } from "svelte";
    import { logout, postPushStatus, requestAccountDeletion } from "../api";
    import LoginForm from "../lib/LoginForm.svelte";
    import Modal from "../lib/Modal.svelte";
    import Spinner from "../lib/Spinner.svelte";
    import ToggleSwitch from "../lib/ToggleSwitch.svelte";
    import { account } from "../store";
    import * as Analytics from "../analytics";
    import logo from "@assets/img/logo.svg";

    let showDeleteModal = false;
    let accountPromise = account.load();

    onMount(() => {
        Analytics.screen("Profile", "Mobile");
    });

    async function setPush(pushEnabled) {
        await postPushStatus(pushEnabled);
        await account.reload();
    }

    async function deleteAccount(e) {
        if (!e.detail) return;

        await requestAccountDeletion();
        logout();
    }
</script>

{#await accountPromise}
    <Spinner />
{:then a}
    {#if a}
        <div class="flex flex-col py-4 gap-5 text-$color-text-profile">
            <div class="text-2xl text-center">
                {a.firstName}
                {a.lastName}
            </div>
            <div class="flex flex-col">
                <div class="section-header">User Details</div>
                <div class="section-row">
                    <div>First Name</div>
                    <div class="section-value">{a.firstName}</div>
                </div>
                <div class="section-row">
                    <div>Last Name</div>
                    <div class="section-value">{a.lastName}</div>
                </div>
            </div>

            <div class="flex flex-col">
                <div class="section-header">Contact Details</div>
                <div class="section-row">
                    <div>Phone</div>
                    <div class="section-value">{a.phoneNumber}</div>
                </div>
                <div class="section-row">
                    <div>Email</div>
                    <div class="section-value">{a.email}</div>
                </div>
            </div>

            <!-- <div class="flex flex-col">
                <div class="section-header">Notifications</div>
                <div class="section-row">
                    <div>Allow Notifications</div>
                    <ToggleSwitch
                        checked={a.pushEnabled}
                        on:change={(e) => setPush(e.detail.target.checked)}
                    />
                </div>
            </div> -->
            <div class="flex flex-col">
                <div class="section-header">Danger Zone</div>
                <button
                    class="btn btn-secondary-danger mx-4"
                    on:click={() => (showDeleteModal = true)}
                >
                    Delete Account
                </button>
            </div>
        </div>
    {:else}
        <div
            class="flex flex-col max-w-250 mx-auto p-5 pt-9 gap-9 text-$color-text-profile"
        >
            <!-- 'max-h-30' to prevent Safari making the image too high. -->
            <img
                src={logo}
                alt="App logo"
                class="max-w-180 max-h-40 self-center"
            />
            <div class="text-center">
                {@html import.meta.env.VITE_PROFILE_LOGIN_PROMPT}
            </div>
            <LoginForm
                register
                on:loginSuccess={() => (accountPromise = account.load())}
            />
        </div>
    {/if}
{/await}

<Modal
    confirm
    header="Delete Account"
    bind:open={showDeleteModal}
    on:result={deleteAccount}
>
    <span slot="body">
        Are you sure you want to delete your account? This will sign you out and
        your account will be permanently deleted within the next 48 working
        hours. This includes all your personal details and shortlist.
    </span>
</Modal>

<style>
    .section-header {
        @apply text-sm px-4 pb-1;
    }

    .section-row {
        @apply flex flex-row justify-between bg-white text-black px-4 items-center h-12;
    }

    .section-value {
        @apply text-true-gray-500;
    }
</style>
