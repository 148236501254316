import { captureException } from "@sentry/core";
import nativeRequest from "./nativeRequest";

/**
 * @param {string} event
 * @param {Record<any, any>?} properties
 */
export function track(event, properties = null) {
    nativeRequest("a_track", {
        ...properties,
        event,
    }).catch((e) => {
        console.error(e);
        captureException(e);
    });
}

/**
 * @param {string} name
 * @param {string} category
 * @param {Record<any, any>} properties
 */
export function screen(name, category = null, properties = null) {
    nativeRequest("a_screen", {
        ...properties,
        name,
        category,
    }).catch((e) => {
        console.error(e);
        captureException(e);
    });
}

export function reset() {
    nativeRequest("a_reset").catch((e) => {
        console.error(e);
        captureException(e);
    });
}
