<script>
    import RangeSlider from "svelte-range-slider-pips";
    import { slide } from "svelte/transition";

    export let label = "Slider";
    export let min = 0;
    export let max = 10;
    export let step = 1;
    export let prefix = "";
    export let formatter = (v) => v;
    export let range = [min, max];

    let visible = false;
    let displayText = "Any";

    $: {
        if (range[0] === min && range[1] === max) displayText = "Any";
        else if (range[0] === min)
            displayText = `Up to ${prefix}${formatter(range[1])}`;
        else if (range[1] === max)
            displayText = `${prefix}${formatter(range[0])}+`;
        else
            displayText = `${prefix}${formatter(
                range[0]
            )} - ${prefix}${formatter(range[1])}`;
    }
</script>

<div class="flex flex-col">
    <button
        class="flex flex-row justify-between bg-white px-4 items-center h-12"
        on:click={() => (visible = !visible)}
    >
        <div>{label}</div>
        <div class="text-true-gray-500">{displayText}</div>
    </button>
    {#if visible}
        <div class="bg-white px-4 py-1" transition:slide={{ duration: 170 }}>
            <RangeSlider
                range
                {min}
                {max}
                {step}
                values={range}
                pips
                first="label"
                last="label"
                {prefix}
                on:change={(e) => (range = e.detail.values)}
                {formatter}
            />
        </div>
    {/if}
</div>
