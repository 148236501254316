<script>
    import { createEventDispatcher } from "svelte";
    import { PlanVisibility } from "../constants";
    import { cachedPlans } from "../store";
    import SampleStamp from "./SampleStamp.svelte";

    const dispatch = createEventDispatcher();

    /** @type {Home} */
    export let plan;

    let availableOffline = false;
    let earTag =
        plan.visibility === PlanVisibility.private
            ? "PRIVATE"
            : plan.visibility === PlanVisibility.team
            ? "TEAM"
            : null;

    $: availableOffline = !!$cachedPlans?.find(
        (p) => p.id === plan.id.toString()
    );

    function getThumbnailUrl() {
        if (plan.currentPhoto?.thumbnail) return plan.currentPhoto.thumbnail;
        else if (plan.currentPhoto?.uploadUrl)
            return plan.currentPhoto.uploadUrl + plan.currentPhoto.sasToken;
        return null;
    }
</script>

<!-- "transform: translateZ(0)" is to fix an issue with Safari where the rounded corners do not mask correctly. -->
<button
    class="bg-white flex flex-col justify-between items-stretch w-full h-full rounded-lg rounded-tr-4xl overflow-hidden shadow-md relative"
    style="transform: translateZ(0); --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.5), 0 2px 4px -1px rgba(0, 0, 0, 0.3);"
    on:click={() => dispatch("click")}
>
    {#if earTag}
        <div
            class="bg-$color-primary text-$color-text-on-primary text-center text-xs p-0.5 flex flex-col justify-end w-40 h-14 absolute top-0 right-0 transform origin-top rotate-45 translate-x-20 z-10"
        >
            {earTag}
        </div>
    {/if}
    <div class="flex flex-row items-center h-12 p-2">
        {#if plan.supplierLogo}
            <img
                class="h-full object-cover"
                src={plan.supplierLogo.thumbnail}
                alt="{plan.supplierName} logo"
            />
        {/if}
    </div>
    <div
        class="relative flex-grow bg-cover bg-center flex flex-col justify-end"
        style="background-image: url('{getThumbnailUrl()}');"
    >
        {#if availableOffline}
            <div
                class="absolute bg-dark-400 left-0 top-0 w-9 h-9 flex justify-center items-center bg-opacity-50 rounded-br-lg backdrop-filter backdrop-blur-lg"
            >
                <span
                    class="material-symbols-rounded material-symbols-rounded-filled text-3xl text-$color-primary"
                >
                    download_for_offline
                </span>
            </div>
        {/if}
        <div class="bg-black bg-opacity-70 px-2 py-1 flex justify-start">
            <p class="text-white text-left">{plan.name}</p>
        </div>
    </div>
    {#if plan.isSample}
        <SampleStamp />
    {/if}
</button>
