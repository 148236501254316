<script>
    import { getDistrictSuburbs, getRegionDistricts } from "../api";
    import { FloorAreas } from "../constants";
    import FilterSlider from "../lib/FilterSlider.svelte";
    import ToggleSwitch from "../lib/ToggleSwitch.svelte";
    import { propertyTypeStore, regionStore } from "../store";

    const numFormat = new Intl.NumberFormat("en-NZ", {
        notation: "compact",
    });

    const maxBedrooms = 8;
    const maxBathrooms = 8;
    const maxBudget = 1500000;

    /** @type {FilterConfig} */
    export let config = null;
    export let showPrivateToggle = false;

    $: if (config) importConfig(config);

    /** @param {FilterConfig} config */
    export function importConfig(config) {
        keywords = config?.keywords || "";
        bedroomRange = [
            config?.minBedrooms || 0,
            config?.maxBedrooms || maxBedrooms,
        ];
        bathroomRange = [
            config?.minBathrooms || 0,
            config?.maxBathrooms || maxBathrooms,
        ];
        budgetRange = [config?.minBudget || 0, config?.maxBudget || maxBudget];
        floorArea = config?.floorArea || 0;
        propertyType = config?.propertyType || 0;
        region = config?.region || 0;
        district = config?.district || 0;
        suburb = config?.suburb || 0;
        showPrivatePlans = config?.showPrivatePlans || false;

        districtsPromise = region ? getRegionDistricts(region) : null;
        suburbsPromise = district ? getDistrictSuburbs(district) : null;
    }

    /** @returns {FilterConfig} */
    export function exportConfig() {
        return {
            keywords,
            minBedrooms: bedroomRange[0],
            maxBedrooms: bedroomRange[1] < maxBedrooms ? bedroomRange[1] : 0,
            minBathrooms: bathroomRange[0],
            maxBathrooms:
                bathroomRange[1] < maxBathrooms ? bathroomRange[1] : 0,
            minBudget: budgetRange[0],
            maxBudget: budgetRange[1] < maxBudget ? budgetRange[1] : 0,
            floorArea,
            propertyType,
            region,
            district,
            suburb,
            showPrivatePlans,
        };
    }

    export function reset() {
        importConfig(null);
    }

    let keywords = "";
    let bedroomRange = [0, maxBedrooms];
    let bathroomRange = [0, maxBathrooms];
    let budgetRange = [0, maxBudget];
    let floorArea = 0;
    let propertyType = 0;
    let region = 0;
    let district = 0;
    let suburb = 0;
    let showPrivatePlans = false;

    let districtsPromise = null;
    let suburbsPromise = null;

    function refreshDistricts() {
        district = 0;
        districtsPromise = region ? getRegionDistricts(region) : null;
        suburb = 0;
        suburbsPromise = null;
    }

    function refreshSuburbs() {
        suburb = 0;
        suburbsPromise = district ? getDistrictSuburbs(district) : null;
    }
</script>

<div class="mx-5 text-center">Filter</div>
<div class="flex flex-col py-4 gap-5">
    <div class="flex flex-col">
        <!-- Keywords field -->
        <div class="section-row">
            <div>Keywords</div>
            <input
                type="text"
                class="section-value"
                placeholder="Enter keywords..."
                bind:value={keywords}
            />
        </div>
    </div>
    <div class="flex flex-col">
        <!-- Bedroom slider -->
        {#if import.meta.env.VITE_HIDE_BEDROOM_COUNT !== "true"}
            <FilterSlider
                max={maxBedrooms}
                label="Bedrooms"
                bind:range={bedroomRange}
            />
        {/if}
        <!-- Bathroom slider -->
        {#if import.meta.env.VITE_HIDE_BATHROOM_COUNT !== "true"}
            <FilterSlider
                max={maxBathrooms}
                label="Bathrooms"
                bind:range={bathroomRange}
            />
        {/if}
        <!-- Budget slider -->
        {#if import.meta.env.VITE_HIDE_BUDGET !== "true"}
            <FilterSlider
                max={maxBudget}
                step={50000}
                label="Budget"
                prefix="$"
                formatter={numFormat.format}
                bind:range={budgetRange}
            />
        {/if}
        <!-- Floor area dropdown -->
        {#if import.meta.env.VITE_HIDE_FLOOR_AREA !== "true"}
            <div class="section-row">
                <div>Floor Area</div>
                <select class="section-value" bind:value={floorArea}>
                    {#each FloorAreas as area, i}
                        <option value={i}>
                            {area.max === 0
                                ? "Any"
                                : `${
                                      area.max === -1
                                          ? ""
                                          : `${
                                                area.min === 0
                                                    ? "0"
                                                    : area.min + "m²"
                                            } - `
                                  }
                            ${area.max === -1 ? "350m² +" : area.max + "m²"}`}
                        </option>
                    {/each}
                </select>
            </div>
        {/if}
    </div>
    <div class="flex flex-col">
        <!-- Property type dropdown -->
        {#if import.meta.env.VITE_HIDE_PROPERTY_TYPE !== "true"}
            <div class="section-row">
                <div>Property Type</div>
                {#await propertyTypeStore.load() then types}
                    <select class="section-value" bind:value={propertyType}>
                        <option value={0}>Any</option>
                        {#each types as type}
                            <option value={type.id}>{type.type}</option>
                        {/each}
                    </select>
                {/await}
            </div>
        {/if}
        {#if import.meta.env.VITE_HIDE_REGION !== "true"}
            <!-- Region dropdown -->
            <div class="section-row">
                <div>Region</div>
                {#await regionStore.load() then regions}
                    <select
                        class="section-value"
                        bind:value={region}
                        on:change={refreshDistricts}
                    >
                        <option value={0}>Any</option>
                        {#each regions as r}
                            <option value={r.id}>{r.name}</option>
                        {/each}
                    </select>
                {/await}
            </div>
            <!-- District dropdown -->
            {#if districtsPromise}
                <div class="section-row">
                    <div>District</div>
                    {#await districtsPromise then districts}
                        <select
                            class="section-value"
                            bind:value={district}
                            on:change={refreshSuburbs}
                        >
                            <option value={0}>Any</option>
                            {#each districts as d}
                                <option value={d.id}>{d.name}</option>
                            {/each}
                        </select>
                    {/await}
                </div>
            {/if}
            <!-- Suburb dropdown -->
            {#if suburbsPromise}
                <div class="section-row">
                    <div>Suburb</div>
                    {#await suburbsPromise then suburbs}
                        <select class="section-value" bind:value={suburb}>
                            <option value={0}>Any</option>
                            {#each suburbs as s}
                                <option value={s.id}>{s.name}</option>
                            {/each}
                        </select>
                    {/await}
                </div>
            {/if}
        {/if}
    </div>
    {#if showPrivateToggle}
        <div class="flex flex-col">
            <div class="section-row">
                <div>Show Private Plans</div>
                <ToggleSwitch bind:checked={showPrivatePlans} />
            </div>
        </div>
    {/if}
</div>

<style>
    .section-row {
        @apply flex flex-row justify-between bg-white px-4 items-center h-12;
    }

    .section-value {
        @apply text-true-gray-500;
    }

    input.section-value {
        @apply text-black text-right placeholder-true-gray-500 focus:outline-none;
    }
</style>
